// @todo: Add these to umbraco settings page instead?
const BOB = {
  PHONE: "+4755547400",
  URL: window.location.origin,
  SOCIAL: {
    FACEBOOK: "https://www.facebook.com/BOBboligbyggelag",
    INSTAGRAM: "https://www.instagram.com/bob_boligbyggelag/",
    LINKEDIN: "https://www.linkedin.com/company/bobbbl",
  },
};

export default BOB;
